import React, { useEffect, useState } from 'react'
import { getPracticeStats, getTeamStats } from '../../../services/StatsService'
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';        
import { ColumnGroup } from 'primereact/columngroup';
import { Row } from 'primereact/row';
import { useParams } from 'react-router-dom';
import { Button } from 'primereact/button';
import { handleAttBackgroundColor, handleRecBackgroundColor, handleSerBackgroundColor } from './Utils/ActionsUtils';
import TableStatsComponent from './Components/TableStatsComponent';
import { Calendar } from 'primereact/calendar';
import { Dropdown } from 'primereact/dropdown';

function ShowTeamStats() {
    
    const {access_key} = useParams()
    const [data, setData] = useState()
    const [startDate, setStartDate] = useState('')
    const [endDate, setEndDate] = useState('')
    const types = [{value: 0, text: 'All'}, {value: 1, text: 'Game Season'}, {value: 2, text: 'Game Pre-Season'}, {value: 3, text: 'Practice Season'}, {value: 4, text: 'Practice Pre-Season'}, ]
    const [selectedType, setSelectedType] = useState(types[0].value)

    useEffect(()=>{
        access_key && handleGetPracticeStats()
    },[access_key])

    const handleGetPracticeStats = () => {
        getTeamStats(access_key, startDate, endDate, selectedType).then((res)=>{
            setData(res)
        })
    }

    return (
        <div className='flex flex-column gap-4 m-4'>
            <div className='flex align-items-center justify-content-between gap-2'>
                {data && data?.length && <div className='text-2xl'>{data[0]?.title}</div>}
                <div className='flex align-items-center gap-2'>
                    <label htmlFor="startDate">Start Date</label>
                    <Calendar name="startDate" id="startDate" value={startDate} onChange={(e)=>setStartDate(e.target.value)} />
                    <label htmlFor="endDate">End Date</label>
                    <Calendar name="endDate" id="endDate" value={endDate} onChange={(e)=>setEndDate(e.target.value)} />
                    <Dropdown value={selectedType} onChange={(e) => setSelectedType(e.value)} options={types} optionLabel="text" 
                    placeholder="Select a Type" />
                    <Button label='Refresh Data' className='w-max' onClick={handleGetPracticeStats} />
                </div>
            </div>
            <TableStatsComponent tableStatsData={data} />
        </div>
    )
}

export default ShowTeamStats